@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,700i");
@import '~normalize.css/normalize.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';
@import '~@blueprintjs/datetime2/lib/css/blueprint-datetime2.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
.u-float--right {
  float: right;
}
.u-float--left {
  float: left;
}

.u-push__left--none {
  margin-left: 0 !important;
}
.u-push__left--xs {
  margin-left: 4px !important;
}
.u-push__left--sm {
  margin-left: 8px !important;
}
.u-push__left--md {
  margin-left: 12px !important;
}
.u-push__left--lg {
  margin-left: 16px !important;
}
.u-push__left--xl {
  margin-left: 32px !important;
}
.u-push__right--none {
  margin-right: 0 !important;
}
.u-push__right--xs {
  margin-right: 4px !important;
}
.u-push__right--sm {
  margin-right: 8px !important;
}
.u-push__right--md {
  margin-right: 12px !important;
}
.u-push__right--lg {
  margin-right: 16px !important;
}
.u-push__right--xl {
  margin-right: 32px !important;
}
.u-push__top--none {
  margin-top: 0 !important;
}
.u-push__top--xs {
  margin-top: 4px !important;
}
.u-push__top--sm {
  margin-top: 8px !important;
}
.u-push__top--md {
  margin-top: 12px !important;
}
.u-push__top--lg {
  margin-top: 16px !important;
}
.u-push__top--xl {
  margin-top: 32px !important;
}
.u-push__bottom--none {
  margin-bottom: 0 !important;
}
.u-push__bottom--xs {
  margin-bottom: 4px !important;
}
.u-push__bottom--sm {
  margin-bottom: 8px !important;
}
.u-push__bottom--md {
  margin-bottom: 12px !important;
}
.u-push__bottom--lg {
  margin-bottom: 16px !important;
}
.u-push__bottom--xl {
  margin-bottom: 32px !important;
}

.u-pad__left--none {
  padding-left: 0 !important;
}
.u-pad__left--xs {
  padding-left: 4px !important;
}
.u-pad__left--sm {
  padding-left: 8px !important;
}
.u-pad__left--md {
  padding-left: 12px !important;
}
.u-pad__left--lg {
  padding-left: 16px !important;
}
.u-pad__left--xl {
  padding-left: 32px !important;
}
.u-pad__right--none {
  padding-right: 0 !important;
}
.u-pad__right--xs {
  padding-right: 4px !important;
}
.u-pad__right--sm {
  padding-right: 8px !important;
}
.u-pad__right--md {
  padding-right: 12px !important;
}
.u-pad__right--lg {
  padding-right: 16px !important;
}
.u-pad__right--xl {
  padding-right: 32px !important;
}
.u-pad__top--none {
  padding-top: 0 !important;
}
.u-pad__top--xs {
  padding-top: 4px !important;
}
.u-pad__top--sm {
  padding-top: 8px !important;
}
.u-pad__top--md {
  padding-top: 12px !important;
}
.u-pad__top--lg {
  padding-top: 16px !important;
}
.u-pad__top--xl {
  padding-top: 32px !important;
}
.u-pad__bottom--none {
  padding-bottom: 0 !important;
}
.u-pad__bottom--xs {
  padding-bottom: 4px !important;
}
.u-pad__bottom--sm {
  padding-bottom: 8px !important;
}
.u-pad__bottom--md {
  padding-bottom: 12px !important;
}
.u-pad__bottom--lg {
  padding-bottom: 16px !important;
}
.u-pad__bottom--xl {
  padding-bottom: 32px !important;
}

.u-flex {
  display: flex;
}

.u-text--caption {
  color: #7f7f7f;
  font-weight: normal;
  font-size: 0.85em;
}
.u-text--size-1 {
  font-size: 1em;
}
.u-text--size-0 {
  font-size: 0.75em;
}
.u-text__color--warning {
  color: #ff1a1a;
}

@media screen and (min-width: 0) {
  .u-width__auto {
    width: auto;
  }
  .u-width__full {
    width: 100%;
  }
  .u-width__half {
    width: 50%;
  }
  .u-display__block {
    display: block;
  }
  .u-display__inline-block {
    display: inline-block;
  }
  .u-display__inline {
    display: inline;
  }
  .u-display__none {
    display: none;
  }
}
@media screen and (min-width: 576px) {
  .u-width__auto--sm {
    width: auto;
  }
  .u-width__full--sm {
    width: 100%;
  }
  .u-width__half--sm {
    width: 50%;
  }
  .u-display__block--sm {
    display: block;
  }
  .u-display__inline-block--sm {
    display: inline-block;
  }
  .u-display__inline--sm {
    display: inline;
  }
  .u-display__none--sm {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .u-width__auto--md {
    width: auto;
  }
  .u-width__full--md {
    width: 100%;
  }
  .u-width__half--md {
    width: 50%;
  }
  .u-display__block--md {
    display: block;
  }
  .u-display__inline-block--md {
    display: inline-block;
  }
  .u-display__inline--md {
    display: inline;
  }
  .u-display__none--md {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .u-width__auto--lg {
    width: auto;
  }
  .u-width__full--lg {
    width: 100%;
  }
  .u-width__half--lg {
    width: 50%;
  }
  .u-display__block--lg {
    display: block;
  }
  .u-display__inline-block--lg {
    display: inline-block;
  }
  .u-display__inline--lg {
    display: inline;
  }
  .u-display__none--lg {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .u-width__auto--xl {
    width: auto;
  }
  .u-width__full--xl {
    width: 100%;
  }
  .u-width__half--xl {
    width: 50%;
  }
  .u-display__block--xl {
    display: block;
  }
  .u-display__inline-block--xl {
    display: inline-block;
  }
  .u-display__inline--xl {
    display: inline;
  }
  .u-display__none--xl {
    display: none;
  }
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.no-wrap {
  white-space: nowrap;
}

.hidden {
  display: none;
}

html {
  scroll-behavior: smooth;
}

.appContent {
  padding-bottom: 20px;
}
.appContent.login {
  padding-bottom: 0px;
}

.appContent > .container {
  padding-left: 20px;
  padding-right: 20px;
}

.bp4-navbar {
  background-color: #ff9500;
  color: #fcfcfc;
  box-shadow: none;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.11);
}
.bp4-navbar .bp4-button {
  color: rgba(255, 255, 255, 0.75);
  font-family: "Montserrat";
  margin-right: 5px;
  transition: 0.25s ease;
  border: none;
}
.bp4-navbar a .bp4-button:hover {
  background: transparent;
  color: #fcfcfc;
}
.bp4-navbar .active > button {
  color: #fcfcfc;
}
.bp4-navbar .desktop-menu {
  display: block;
}
.bp4-navbar .mobile-menu {
  display: none;
}
.bp4-navbar .mobile-menu-toggle {
  display: none;
}
.bp4-navbar .nav-divider {
  display: none;
}
.bp4-navbar.deliver-pro {
  background-color: #06f;
}
@media screen and (max-width: 767px) {
  .bp4-navbar .desktop-menu {
    display: none;
  }
  .bp4-navbar .desktop-logout {
    display: none;
  }
  .bp4-navbar .mobile-menu {
    display: block;
    opacity: 0;
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 100vw;
    top: 0px;
    transition: left 0.3s, opacity 0.3s;
  }
  .bp4-navbar .mobile-menu .mobile-menu-fade {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.66);
  }
  .bp4-navbar .mobile-menu .mobile-menu-close {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 15px;
    cursor: pointer;
  }
  .bp4-navbar .mobile-menu a {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  .bp4-navbar .mobile-menu a button {
    height: 60px;
  }
  .bp4-navbar .mobile-menu .mobile-menu-link-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    background-color: #ff9500;
  }
  .bp4-navbar .mobile-menu-toggle {
    display: block;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 10px;
  }
  .bp4-navbar .mobile-menu-toggle.mobile-menu-toggle-hide {
    display: none;
  }
  .bp4-navbar .mobile-menu-show {
    display: block;
    left: 0px;
    opacity: 1;
  }
}

.bp4-navbar-divider {
  border: 0.75px solid #fcfcfc;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Montserrat", sans-serif;
}

a {
  color: #162fda;
}
a:hover {
  color: #0f2095;
}

a:hover {
  text-decoration: none !important;
}

h1.pageTitle {
  margin-bottom: 20px;
  font-size: 36px;
  color: #ff9500;
}
@media (max-width: 767px) {
  h1.pageTitle {
    margin-bottom: 5px;
  }
}
h1.pageTitleDeliverPro {
  color: #06f;
}

body {
  color: #373737;
  font-family: "Montserrat";
}

label.bp4-label {
  color: #7f7f7f;
}

.bp4-input-group input,
.bp4-form-group input {
  font-size: 15px !important;
  padding: 21px 15px 22px;
  box-shadow: none;
  transition: all 0.2s ease;
}
.bp4-input-group.bp4-input-group-icon > input,
.bp4-form-group.bp4-input-group-icon > input {
  padding-left: 40px !important;
}
.bp4-input-group .bp4-icon,
.bp4-form-group .bp4-icon {
  margin: 14px !important;
  color: #929292;
}
.bp4-input-group.location-search-input input,
.bp4-form-group.location-search-input input {
  padding: 21px 22px 22px 37px !important;
}
.bp4-input-group .bp4-input__addon,
.bp4-form-group .bp4-input__addon {
  background: #cdced0;
  height: 43px;
  line-height: 43px;
  padding-left: 16px;
  padding-right: 12px;
  margin-right: 10px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.bp4-input-group .bp4-input__addon:not(.bp4-input__addon--breakpoint),
.bp4-form-group .bp4-input__addon:not(.bp4-input__addon--breakpoint) {
  min-width: 9em;
}
@media screen and (max-width: 767px) {
  .bp4-input-group .bp4-input__addon:not(.bp4-input__addon--breakpoint),
  .bp4-form-group .bp4-input__addon:not(.bp4-input__addon--breakpoint) {
    min-width: 4em;
  }
}

.bp4-input:focus, .PhoneInput .PhoneInputInput:focus,
.bp4-input.bp4-active,
.PhoneInput .bp4-active.PhoneInputInput,
.bp4-card.bp4-interactive:hover,
.bp4-card.bp4-interactive:active {
  box-shadow: 0 2px 4px rgba(16, 22, 26, 0.3);
}

.bp4-file-upload-input {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #7f7f7f;
  width: 150px;
  font-weight: 500;
}
.bp4-file-upload-input:after {
  display: none;
}

.bp4-html-select.select {
  width: 100%;
}
.bp4-html-select.select select {
  min-height: 43px;
  background: #fcfcfc;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: all 0.2s ease;
}
.bp4-html-select.select select:hover {
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(16, 22, 26, 0.3);
  -webkit-box-shadow: 0 2px 4px rgba(16, 22, 26, 0.3);
}
.bp4-html-select.select .bp4-icon {
  margin: 0 !important;
}

.bp4-file-input {
  width: 100%;
}
.bp4-file-input .bp4-file-upload-input {
  min-height: 43px;
  padding: 6px 18px;
}

.bp4-slider {
  margin-bottom: -10px;
}
.bp4-slider .bp4-slider-axis .bp4-slider-label {
  display: none;
}

.formRow {
  display: flex;
}
.formRow .bp4-form-group {
  margin-right: 22px;
  flex: 1;
}
.formRow .bp4-form-group:last-of-type {
  margin-right: 0;
}

.whiteLabel > label {
  color: #fff;
}

.whiteLabel > .bp4-form-group-sub-label {
  color: #fcfcfc;
}

.formError {
  color: #fcfcfc;
  background: #ff9500;
  border-radius: 4px;
  font-weight: bold;
  padding: 12px;
  margin-bottom: 12px;
}

.bp4-control input:checked ~ .bp4-control-indicator {
  background-color: #162fda;
}

.bp4-control:hover input:checked ~ .bp4-control-indicator {
  background-color: #0f2095;
}

.inlineCheckbox {
  font-size: 0.9em;
  display: inline;
  margin-left: 6px;
  font-weight: 500;
}
.inlineCheckbox span.bp4-control-indicator {
  margin-right: 4px;
}

.appContent .bp4-button:not([class*=bp4-intent-]),
.bp4-portal .bp4-button:not([class*=bp4-intent-]) {
  background: none;
  border: 1px solid #162fda;
  border-radius: 4px;
  font-family: "Montserrat";
  color: #162fda;
  box-shadow: none;
  font-weight: 600;
  transition: all 0.2s ease;
}
.appContent .bp4-button:not([class*=bp4-intent-]).minimalButton,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).minimalButton {
  border: 0;
  color: #373737;
  padding-left: 0;
  padding-right: 0;
}
.appContent .bp4-button:not([class*=bp4-intent-]).minimalButton:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).minimalButton:hover {
  background: none;
  border: none;
  color: #162fda;
}
.appContent .bp4-button:not([class*=bp4-intent-]).minimalButton.bp4-disabled,
.appContent .bp4-button:not([class*=bp4-intent-]).minimalButton :disabled,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).minimalButton.bp4-disabled,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).minimalButton :disabled {
  border: none;
}
.appContent .bp4-button:not([class*=bp4-intent-]).primaryButton,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).primaryButton {
  color: #ff9500;
  border: 1px solid #ff9500;
}
.appContent .bp4-button:not([class*=bp4-intent-]).primaryButton:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).primaryButton:hover {
  color: #fcfcfc;
}
.appContent .bp4-button:not([class*=bp4-intent-]).primaryButtonFilled,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).primaryButtonFilled {
  background: #ff9500;
  color: #fcfcfc;
  border: 1px solid #ff9500;
}
.appContent .bp4-button:not([class*=bp4-intent-]).primaryButtonFilled:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).primaryButtonFilled:hover {
  background: #cc7700;
}
.appContent .bp4-button:not([class*=bp4-intent-]).secondaryButton,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).secondaryButton {
  color: #162fda;
  border: 1px solid #162fda;
}
.appContent .bp4-button:not([class*=bp4-intent-]).secondaryButton:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).secondaryButton:hover {
  color: #fcfcfc;
}
.appContent .bp4-button:not([class*=bp4-intent-]).secondaryButtonFilled:not([class*=bp4-disabled]),
.bp4-portal .bp4-button:not([class*=bp4-intent-]).secondaryButtonFilled:not([class*=bp4-disabled]) {
  background: #162fda;
  color: #fcfcfc;
  border: 1px solid #162fda;
}
.appContent .bp4-button:not([class*=bp4-intent-]).secondaryButtonFilled:not([class*=bp4-disabled]):hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).secondaryButtonFilled:not([class*=bp4-disabled]):hover {
  background: #1125ac;
}
.appContent .bp4-button:not([class*=bp4-intent-]).secondaryButtonFilled:not([class*=bp4-disabled]).shipButton,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).secondaryButtonFilled:not([class*=bp4-disabled]).shipButton {
  min-width: 200px;
}
.appContent .bp4-button:not([class*=bp4-intent-]).warningButton,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).warningButton {
  color: #ff1a1a;
  border: 1px solid #ff1a1a;
}
.appContent .bp4-button:not([class*=bp4-intent-]).warningButton:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).warningButton:hover {
  color: #fcfcfc;
  background: #ff1a1a;
}
.appContent .bp4-button:not([class*=bp4-intent-]).whiteButton,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).whiteButton {
  color: #fcfcfc;
  border: 1px solid #fcfcfc;
}
.appContent .bp4-button:not([class*=bp4-intent-]).whiteButton:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).whiteButton:hover {
  color: #162fda;
  background: #fcfcfc;
}
.appContent .bp4-button:not([class*=bp4-intent-]):disabled, .appContent .bp4-button:not([class*=bp4-intent-]).bp4-disabled,
.bp4-portal .bp4-button:not([class*=bp4-intent-]):disabled,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).bp4-disabled {
  border: 1px solid #7f7f7f;
  color: #7f7f7f;
}
.appContent .bp4-button:not([class*=bp4-intent-]):disabled:hover, .appContent .bp4-button:not([class*=bp4-intent-]).bp4-disabled:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]):disabled:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).bp4-disabled:hover {
  background: none;
}
.appContent .bp4-button:not([class*=bp4-intent-]).deliverProButtonFilled,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).deliverProButtonFilled {
  background: #06f;
  color: #fcfcfc;
}
.appContent .bp4-button:not([class*=bp4-intent-]).deliverProButtonFilled:hover,
.bp4-portal .bp4-button:not([class*=bp4-intent-]).deliverProButtonFilled:hover {
  background: #0052cc;
}

.bp4-button:not([class*=bp4-intent-]):hover {
  background-color: #162fda;
  color: #fcfcfc;
}
.bp4-button:not([class*=bp4-intent-]):hover.primaryButton {
  background-color: #ff9500;
}

.bp4-button .bp4-icon {
  color: inherit;
}

p.warningMessage {
  margin-top: 15px;
  padding: 12px;
  background: #ffc7c7;
  border: 1px solid #ff1a1a;
}

.error {
  color: #ff1a1a;
  padding: 4px 0;
}

.basic-appear {
  opacity: 0.01;
  transform: translateX(200px);
}

.basic-appear.basic-appear-active {
  opacity: 2;
  transform: translateX(0px);
  transition: all 0.4s ease-in-out;
}

.centerImage {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

hr {
  border: none;
  border-top: 1px solid #cdced0;
}

.u-text--warning {
  color: #ff1a1a;
}
.u-text--primary {
  color: #ff9500;
}

.divider--vertical:not(:first-child) {
  border-left: 2px solid rgb(217, 217, 217);
}

table.bp4-html-table {
  width: 100%;
}
table.bp4-html-table td {
  padding: 13px;
}
table.bp4-html-table td.bp4-skeleton {
  padding: 9px;
  margin: 4px;
}

.draggable-dropoff {
  position: relative;
  margin-bottom: 14;
  background-color: #e7e7e7;
  border-radius: 4px;
  margin: 8px 0px;
  padding: 1.5rem 0.75rem;
}
.draggable-dropoff .move-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.draggable-dropoff .move-buttons .move-button {
  margin-left: 0.25rem;
  border-radius: 50%;
}
.draggable-dropoff .match-stop-alpha-id {
  display: inline-block;
  background: #ff9500;
  color: #fcfcfc;
  font-weight: bold;
  min-width: 1.25em;
  height: 1.25em;
  padding: 0.125em;
  text-align: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 4px;
  margin-right: 0.375rem;
}
.draggable-dropoff .drag-button {
  cursor: grab;
  color: #7f7f7f;
  float: right;
  display: flex;
  align-self: flex-end;
  margin-top: -0.75rem;
}

.items .items-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.items .items-header .add-item {
  border-radius: 50%;
  color: #162fda;
  border: 1px solid #162fda;
}
.items .item {
  border-top: 1px solid #cdced0;
}
.items .item .bp4-collapse > div {
  padding-bottom: 1rem;
}
.items .item .item-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.125rem;
}
.items .item .item-label .toggle-item,
.items .item .item-label .remove-item {
  flex-shrink: 0;
  padding: 0.675rem;
}
.items .item .item-label .remove-item {
  color: #ff1a1a;
}
.items .item .item-label .remove-item:disabled {
  visibility: hidden;
}
.items .item .item-label .toggle-item {
  color: #162fda;
}
.items .item .item-label .item-desc {
  display: block;
  text-align: center;
  font-weight: bold;
  flex-grow: 1;
}

.card {
  background: #fff;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}
.card.left-aligned {
  justify-content: left;
}
.card :not(.shipper-instruction) h3 {
  position: relative;
  top: -4px;
  margin-bottom: 0;
}
.card :not(.shipper-instruction) h3 > .text-button {
  color: #373737 !important;
  font-weight: 700;
  font-size: 1rem;
  font-family: "Montserrat";
}
.card :not(.shipper-instruction) p {
  font-size: 0.85em;
  margin-bottom: 0;
}
.card .cardImage {
  flex: 0.9;
  min-height: 52px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
.card .cardImage img {
  width: 100%;
  padding-right: 12px;
}
.card .cardText {
  flex: 1.1;
}
.card label.cardTitle {
  font-weight: 600;
}
.card.cardSelected {
  color: #162fda;
}
.card.cardSelected .text-button {
  color: #162fda !important;
}
.card.preferredDriverSelectPadding {
  padding: 15px;
}
.card.preferredDriverSelectPadding .row {
  margin-left: 0;
}
.card.preferredDriverSelectPadding .cardTitle {
  margin-top: 0;
}

.deliverProDriverCard {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: white;
  border-radius: 6px;
}
.deliverProDriverCard .deliverProDriver {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.deliverProDriverCard .deliverProVehicle {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.deliverProDriverCard .driverTitle {
  color: #8a838a;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
.deliverProDriverCard .driverName {
  color: #373737;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.deliverProDriverCard .driverDetails {
  color: #8a838a;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}
.deliverProDriverCard .divider {
  width: 1px;
  flex-shrink: 0;
  align-self: stretch;
  background: #ebebeb;
  margin-left: 7px;
  margin-right: 7px;
}
.deliverProDriverCard .deliverProVehicleImage {
  height: 38px;
}
.deliverProDriverCard .vehicleText {
  color: #989298;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.shrinkSize .card {
  flex-direction: column;
}
.shrinkSize .card .cardText {
  margin-top: 6px;
}

.shrinkSize h2.estimate {
  font-size: 1.6em;
}

.buttonRight {
  float: right !important;
  min-height: 70px;
  margin-top: 27px;
  margin-bottom: 40px;
}

.PlacesAutocomplete {
  position: relative;
}

.PlacesAutocompleteDropdownContainer {
  width: 100%;
  position: absolute;
  z-index: 1000;
  padding-top: 2px;
  background-color: #f8f8f8;
  border: 1px solid #fcfcfc;
  border-top: 0;
  padding-bottom: 0.5rem;
}
.PlacesAutocompleteDropdownContainer .suggestion-item {
  padding: 0.5rem 1rem;
}
.PlacesAutocompleteDropdownContainer .suggestion-item--active {
  background-color: #f3f3f3;
  cursor: pointer;
}

.suggestedDriver .bp4-fill {
  flex-grow: 0 !important;
}

.suggestedDriverLabel {
  background-color: #0066ff;
  color: white !important;
  text-transform: uppercase;
  font-size: 10px;
  border-radius: 15px;
  padding: 2px 8px;
}

.deliverProBadge {
  display: inline-flex;
  align-items: center;
  margin-right: 0.25rem;
}

.driverLabels {
  display: inline-flex;
  flex-direction: row;
}

.driverSelectContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.defaultDriverButton {
  cursor: pointer;
  color: #0066ff;
}

@media screen and (max-width: 767px) {
  .dropoffMobileButton {
    margin-top: 20px;
    padding: 15px;
  }
  .buttonRight {
    margin-top: 0px;
    width: 100%;
  }
}
ol.stopList {
  counter-reset: stop;
  margin-left: 0;
  padding-left: 0;
}
ol.stopList li {
  display: block;
  margin-bottom: 1em;
  margin-left: 2em;
}
ol.stopList li:before {
  display: inline-block;
  content: "#" counter(stop);
  counter-increment: stop;
  width: 2em;
  margin-left: -2em;
  position: absolute;
  color: #ff9500;
  font-weight: bold;
}

.statusPane {
  color: #373737;
}
.statusPane p.statusDescription {
  margin-bottom: 0;
}
.statusPane p.statusDescription .statusRight {
  float: right;
}
.statusPane p.statusDescription .statusRight .bp4-icon {
  position: relative;
  top: -2px;
}
.statusPane h2 {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: 0.25rem;
}
.statusPane .bp4-card {
  background-color: #f3f3f3;
  border-left: 4px solid #ff9500;
  border-radius: 0 5px 5px 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.statusPane .bp4-card .bp4-progress-bar {
  margin-bottom: 25px;
}
.statusPane .bp4-card .cardInset {
  background: #fcfcfc;
  margin-top: 15px;
  padding: 8px;
  padding: 10px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
}
.statusPane .bp4-card .cardInset p.statusDescription {
  font-size: 0.9em;
}
.statusPane .bp4-card .cardInset p.statusNote {
  padding-top: 4px;
  font-size: 0.85em;
  margin-bottom: 0;
}
.statusPane .bp4-card .cardInset h2 {
  font-size: 1.05em;
  margin-bottom: 0;
}
.statusPane .bp4-card .cardInset .cardInset--icon {
  flex-shrink: 1;
  padding-right: 12px;
}
.statusPane .bp4-card .cardInset .cardInset--data {
  flex: 1;
}
.statusPane .bp4-card .cardDivider {
  display: block;
  width: 100%;
  height: 5px;
  background: #fcfcfc;
}
.statusPane .bp4-card .cardThreeColumn {
  display: flex;
  margin-right: -10px;
}
.statusPane .bp4-card .cardThreeColumn .cardInset {
  margin-right: 10px;
}
.statusPane a.backLink {
  font-size: 1em;
  font-weight: normal;
  color: #7f7f7f;
}
.statusPane a.backLink .bp4-icon {
  margin-right: 3px;
  position: relative;
  top: 2px;
}

.infoCard {
  background: #f3f3f3;
}
.infoCard h2,
.infoCard h3 {
  margin-top: 0;
}
.infoCard .sameRow {
  display: flex;
}
.infoCard .sameRow .bp4-form-group {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}
.infoCard .sameRow .bp4-form-group:first-of-type {
  margin-left: 0;
}
.infoCard .sameRow .bp4-form-group:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .infoCard .sameRow.exceptMobile {
    display: initial;
  }
  .infoCard .sameRow.exceptMobile .bp4-form-group {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media screen and (min-width: 767px) {
  .accountCol {
    margin-bottom: 20px;
  }
}
.calloutError {
  background-color: rgb(241, 222, 221) !important;
}

.UtilityScreen {
  text-align: center;
}
.UtilityScreen h2 {
  font-size: 4rem;
  margin-top: 1em;
  margin-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .UtilityScreen h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .registerColumn.rightColumn {
    padding: 40px 20px;
  }
}
.agreement-dark .bp4-label,
.agreement-dark span {
  color: white;
}
.agreement-dark a {
  color: white;
  text-decoration: underline;
}
.agreement-dark a:hover, .agreement-dark a:focus {
  color: #ccc;
}

.login-fullheight {
  height: 100vh;
  width: 100% !important;
  border-bottom: 0px;
  background: url("./assets/images/background-shippers.jpg") no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-centered {
  position: relative;
  max-height: 100%;
  overflow: auto;
  min-height: 100vh;
}
.login-centered .sameRow {
  display: flex;
}
.login-centered .sameRow .bp4-form-group {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}
.login-centered .sameRow .bp4-form-group:first-of-type {
  margin-left: 0;
}
.login-centered .sameRow .bp4-form-group:last-of-type {
  margin-right: 0;
}

.loginHeader {
  color: #ff9500;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 2.8em;
}

.loginSubtitle {
  color: #ff9500;
  text-align: center;
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 20px;
}

.loginOptionSelect {
  color: #fcfcfc;
  font-size: 1.25em;
  text-align: center;
}

.loginDriver {
  color: #fcfcfc;
  text-align: center;
  font-size: 1.15em;
  font-weight: normal;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  background: url("./assets/images/vehicles-alt.png") no-repeat left, rgb(0, 102, 255);
  background-color: rgb(0, 102, 255);
}
.loginDriver a {
  color: #fcfcfc;
  text-decoration: underline;
}

@media (max-width: 568px) {
  .loginHeader {
    font-size: 1.7em;
  }
  .unusedMobileColumn {
    display: none;
  }
}
.registerButton {
  padding: 15px;
  margin-top: 20px;
  background: rgba(30, 30, 30, 0.6);
  border-radius: 5px;
}
.registerButton .registerLabel {
  font-weight: 500;
  color: #fcfcfc;
  font-size: 14px;
  text-transform: uppercase;
}

.registerButton--shipScreen {
  padding: 15px;
  margin-top: 20px;
  background: transparent;
  border: 1px solid #7f7f7f;
  border-radius: 5px;
}
.registerButton--shipScreen .registerLabel--shipScreen {
  font-weight: 500;
  color: #7f7f7f;
  font-size: 14px;
  text-transform: uppercase;
}

.registerButton--shipScreen {
  padding: 15px;
  margin-top: 20px;
  background: transparent;
  border: 1px solid #1e1e1e;
  border-radius: 5px;
}
.registerButton--shipScreen .registerLabel--shipScreen {
  font-weight: 500;
  color: #1e1e1e;
  font-size: 14px;
  text-transform: uppercase;
}

.forgotPasswordLabel {
  text-align: right;
}
.forgotPasswordLabel .text-button {
  color: #fcfcfc;
}
.forgotPasswordLabel .text-button:hover {
  color: #7f7f7f;
}

.forgotPasswordLabelDark {
  text-align: right;
}
.forgotPasswordLabelDark a {
  color: #1e1e1e;
}
.forgotPasswordLabelDark a:hover {
  color: #000;
}

.appContent {
  display: grid;
  align-items: center;
  height: 100vh;
}

.welcomeText {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.backgroundCircle {
  background-color: #ff9500;
  width: 103px;
  height: 103px;
  border-radius: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  color: #fcfcfc;
  font-size: 52px;
}

.platformCard {
  background-color: #f3f3f3;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 14px;
  margin-left: 14px;
  padding: 16px;
  border-radius: 5px;
}

.platformCard img {
  margin-right: 7px;
}

.titleText {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.bodyText {
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: left;
}

.deliverProTitle {
  color: #fcfcfc;
  font-family: Montserrat;
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
}

.trackingRefresh {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  line-height: 0.9;
  font-size: 12.6px;
}
.trackingRefresh__datetime {
  display: flex;
  flex-direction: row;
}
.trackingRefresh__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #0066ff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
}
.trackingRefresh__icon {
  font-weight: 900;
  margin-right: 4px;
}
.trackingRefresh__text {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
}

.homepageCard {
  background-color: #f3f3f3;
  border: none;
  box-shadow: none;
  margin: 20px 0;
}

.leftColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 15px 15px;
}
.leftColumn .actionForm {
  flex: 1;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
}
@media screen and (min-width: 992px) {
  .leftColumn .actionForm {
    min-width: 600px;
  }
}

.rightColumn,
.leftColumn {
  padding: 10px;
  margin-top: 20px;
}

.loginError {
  color: white;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .homepageCard {
    display: none;
  }
  .leftColumn {
    margin-top: 50px;
  }
}
.flat-card {
  background-color: #f3f3f3;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.statusPane .bp4-card .cardInset div.cardOption {
  display: flex;
  flex: 2;
  gap: 10px;
}

.statusPane .bp4-card .cardInset div.cardOption .cardInset--select {
  flex: 1;
  padding: 1px;
}

.statusPane .bp4-card .cardInset div.cardOption .cardInset--select button {
  width: 100%;
}

.statusPane .bp4-card .cardInset div.cardOption .cardInset--button {
  padding: 0;
  flex: 1;
  border-radius: 5px;
  font-weight: 500;
  background-color: #0266ff;
}

.driver-options-container {
  margin-top: 0px !important;
  justify-content: space-evenly;
}

.preferred-container {
  background: #fcfcfc;
}

.shipTab {
  width: 100%;
  margin-bottom: 20px;
}
.shipTab .bp4-tabs > .bp4-tab-list .bp4-tab-indicator {
  background-color: #162fda;
}
.shipTab .bp4-tabs > .bp4-tab-list .bp4-tab {
  padding: 8px 30px 8px 13px;
  font-size: 1.1rem;
  font-weight: 700;
  margin-right: 0;
}
.shipTab .bp4-tabs > .bp4-tab-list .bp4-tab.touched {
  color: #162fda;
}
.shipTab .bp4-tabs > .bp4-tab-list .bp4-tab span:first-child:after {
  content: ".";
}
.shipTab .bp4-tabs > .bp4-tab-list .bp4-tab[aria-selected=true] {
  color: #162fda !important;
}
.shipTab .bp4-tabs > .bp4-tab-panel {
  background-color: #f3f3f3 !important;
  padding: 22px 22px;
  width: 100%;
  position: relative;
  border-radius: 8px;
}
@media screen and (max-width: 1199px) {
  .shipTab .bp4-tabs > .bp4-tab-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .shipTab .bp4-tabs > .bp4-tab-list .bp4-tab-indicator-wrapper {
    display: none;
  }
  .shipTab .bp4-tabs > .bp4-tab-list .bp4-tab {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #cdced0 !important;
    padding: 5px 0;
    text-align: center;
    color: #fcfcfc;
  }
  .shipTab .bp4-tabs > .bp4-tab-list .bp4-tab.touched {
    background-color: #162fda !important;
    color: #fcfcfc !important;
  }
  .shipTab .bp4-tabs > .bp4-tab-list .bp4-tab span:first-child:after {
    content: "";
  }
  .shipTab .bp4-tabs > .bp4-tab-list .bp4-tab span:last-child {
    display: none;
  }
}

.bp4-label {
  color: #7f7f7f;
  font-weight: 500;
}
.bp4-label .bp4-text-muted {
  color: #7f7f7f;
  font-size: 0.88em;
  font-weight: 400;
}

.card {
  border-radius: 4px;
  box-shadow: none;
}

.contractCard {
  padding: 15px;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.contractCard .contractLabel {
  position: absolute;
  right: 15px;
  top: 15px;
}

.frayt-icon {
  background: url("/public/favicon.ico");
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  float: right;
  display: block;
}

.bullet-tag {
  padding: 0.5px 6px;
  width: 90px;
  height: 20px;
  background: #d9d9d9;
  border-radius: 3px;
  float: right;
}

.shipper-instruction {
  font-size: 13px;
  line-height: 16px;
  color: #8a838a;
  padding: 0 0 0 10px;
  line-height: 1.5;
}
.shipper-instruction p {
  margin-bottom: 5px;
}
.shipper-instruction p:last-of-type {
  margin-bottom: 0;
}

.cardText h3 {
  margin-top: 0;
}
.cardText h3 a {
  color: #fcfcfc;
}

.panelDivider {
  height: 1px;
  border-bottom: 4px solid #fcfcfc;
  background: #fcfcfc;
  margin-top: 30px;
  margin-left: -22px;
  margin-right: -22px;
}

.fee-waiver-label {
  position: absolute;
  top: 0.85rem;
  left: 14rem;
  background-color: #00cd4d;
  border-radius: 5px;
  color: #fcfcfc;
  font-size: 0.6rem;
  text-transform: uppercase;
  padding: 0.166665rem 0.8rem;
}

.estimate-banner {
  display: flex;
}
.estimate-banner__header {
  cursor: pointer;
}
.estimate-banner__title {
  background-color: #fff;
  padding: 7px 10px;
  font-size: 18px;
  color: #ff9500;
  position: relative;
  top: -3px;
  border-radius: 4px;
  margin-right: 5px;
}
.estimate-banner__title-deliver_pro {
  background-color: #fff;
  padding: 7px 10px;
  font-size: 18px;
  color: #06f;
  position: relative;
  top: -3px;
  border-radius: 4px;
  margin-right: 5px;
}
@media screen and (max-width: 1024px) {
  .estimate-banner {
    display: flex;
    justify-content: center;
  }
  .estimate-banner .bp4-popover2-target {
    width: 100%;
    padding-bottom: 1rem;
  }
  .estimate-banner__header {
    text-align: center;
  }
  .estimate-banner__title {
    display: block;
    width: 100%;
  }
}

.item-form-group__dimensions {
  display: flex;
  flex-direction: row;
}
.item-form-group__dimensions > div {
  margin-right: 4px;
}
.item-form-group__dimensions > div:last-child {
  margin-right: 0px;
}

.step-footer {
  padding-top: 25px;
  padding-bottom: 1px;
}

.spinnerLeft {
  display: block;
  width: 40px;
  margin-bottom: 12px;
}

.radioLabel {
  font-weight: 700;
  font-size: 15px;
  margin-top: 0 !important;
}

.radioLabelInner {
  cursor: pointer;
  font-weight: 400;
  display: block;
  margin-top: 5px;
  color: #7f7f7f;
}

.scheduledRadio {
  width: fit-content;
}

.appCushion {
  margin-top: 75px;
}
@media (max-width: 767px) {
  .appCushion {
    margin-top: 55px;
  }
}

a.calloutLink {
  display: block;
  margin-top: 6px;
  margin-bottom: 3px;
}
a.calloutLink .bp4-icon {
  margin-left: 3px;
  position: relative;
  top: -1px;
}

.headerWithButton {
  justify-content: space-between;
}

.barLoaderContainer {
  clear: both;
  margin-top: 50px;
  margin-bottom: 50px;
}

.matchRow {
  display: flex;
  background-color: #f3f3f3;
  border-left: 4px solid #ff9500;
  border-radius: 0 4px 4px 0;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.matchRow .matchRow__container {
  padding: 25px;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  flex: 100;
  flex-wrap: wrap;
}
.matchRow .matchRow__container:last-of-type {
  flex: 20;
  justify-content: center;
  border-left: 3px solid #fcfcfc;
}
.matchRow .matchRow__container .matchRow__actions {
  margin: 0 20px;
}
@media (max-width: 767px) {
  .matchRow .matchRow__container {
    padding: 17px;
  }
}
.matchRow .matchRow__subrow {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  flex-wrap: wrap;
}
.matchRow .matchRow__subrow:last-of-type {
  margin: 0px;
}
@media (max-width: 767px) {
  .matchRow .matchRow__subrow {
    margin-right: 0;
  }
}
.matchRow .matchRow__subrow .matchRow--header {
  background-color: transparent;
  padding: 0 0 10px 0;
}
.matchRow .matchRow__subrow .matchRow--header .matchRow__label {
  color: #7f7f7f;
  font-size: 15px;
}
.matchRow .matchRow__subrow .matchRow--header h3 {
  font-weight: 600;
  font-size: 18px;
  color: #373737;
}
.matchRow .matchRow__subrow .matchRow--header:last-of-type {
  margin-bottom: 10px;
}
.matchRow h3 {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
}
.matchRow .matchRow__category {
  flex: 1.3;
  background-color: #fcfcfc;
  border-radius: 4px;
  padding: 20px 40px;
  margin: 10px;
  color: #7f7f7f;
}
@media screen and (max-width: 1024px) {
  .matchRow .matchRow__category {
    min-width: 200px;
  }
}
@media screen and (min-width: 767px) {
  .matchRow .matchRow__category {
    flex: 1;
    padding: 10px 15px;
    margin: 0px 0px 0px 5px;
  }
  .matchRow .matchRow__category:last-of-type {
    margin-right: 0;
  }
  .matchRow .matchRow__category.matchRow__category--large {
    flex: 1.3;
  }
}
.matchRow .matchRow__category .bp4-icon {
  vertical-align: initial;
}
.matchRow .matchRow__action {
  flex-shrink: 1;
}
@media screen and (max-width: 767px) {
  .matchRow .matchRow__actionButton {
    height: 100%;
    width: 100%;
    padding: 10px;
  }
  .matchRow .matchRow__container:first-child {
    padding-bottom: 0px;
  }
  .matchRow .matchRow__category {
    padding: 10px 20px;
    min-width: 200px;
    margin: 0px;
  }
  .matchRow .matchRow__category:not(.matchRow--header) {
    margin-bottom: 10px;
  }
}
.matchRow .matchRow__details {
  flex-shrink: 1;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.matchRow .matchRow__label {
  margin: 0 0 5px;
  font-size: 14px;
}
.matchRow .matchRow__icon {
  margin-right: 4px;
}
.matchRow .actionButton {
  margin-top: 10px;
}

.reviewBox {
  border-radius: 8px;
  padding: 20px 20px 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  background: #fcfcfc;
}
.reviewBox h2 {
  margin-top: 0;
  font-size: 1.4em;
}
.reviewBox button.reviewLink {
  font-size: 0.7em;
  font-weight: normal;
  float: right;
  color: #7f7f7f;
}
.reviewBox button.reviewLink .bp4-icon {
  margin-left: 3px;
  position: relative;
}
.reviewBox .oneHalf,
.reviewBox .oneThird {
  display: flex;
}
.reviewBox .infoBox {
  padding: 15px;
  background: #f3f3f3;
  margin-bottom: 15px;
  border-radius: 4px;
}
.reviewBox .infoBox.infoBox--row {
  flex: 1;
}
.reviewBox .infoBox.infoBox--icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reviewBox .infoBox.infoBox--center {
  text-align: center;
}
.reviewBox .infoBox.infoBox--center p {
  margin-top: 8px;
}
.reviewBox .infoBox.infoBox--bold p {
  font-weight: bold;
}
.reviewBox .infoBox img {
  width: auto;
  max-height: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.reviewBox .infoBox p {
  font-size: 1.2em;
  margin-bottom: 0;
}
.reviewBox .infoBox:last-of-type {
  margin-right: 0;
}
.reviewBox .infoBox .infoBox__icon {
  float: left;
  margin-right: 6px;
  color: #929292;
}
.reviewBox .infoBox .infoBox--iconHalf {
  flex-shrink: 1;
  margin-right: 8px;
}
.reviewBox .infoBox .infoBox--iconHalf .bp4-icon {
  margin-right: 0;
}
.reviewBox .infoBox .infoBox--dataHalf {
  flex: 1;
}
.reviewBox .infoBox .heading {
  font-size: 0.9em;
  padding-bottom: 5px;
}
.reviewBox .oneThird > .infoBox {
  font-size: 0.9em;
  padding: 10px;
}
.reviewBox .oneThird > .infoBox .heading {
  padding-bottom: 5px;
}
.reviewBox .ratingBox {
  margin-bottom: 15px;
}
.reviewBox .ratingBox__interior {
  justify-content: center;
}
.reviewBox .ratingBox a {
  padding: 5px;
}
.reviewBox .ratingBox a:first-child span {
  margin-top: 5px;
}
.reviewBox .ratingBox a:last-child span {
  margin-bottom: 5px;
}
@media screen and (min-width: 767px) {
  .reviewBox .oneHalfDesktop {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .reviewBox .oneHalfDesktop .infoBox.infoBox--row {
    margin-right: 10px;
    margin-left: 10px;
  }
  .reviewBox .oneHalfDesktop .infoBox.infoBox--row:first-child {
    margin-left: 0px;
  }
  .reviewBox .oneHalfDesktop .infoBox.infoBox--row:last-child {
    margin-right: 0px;
  }
}
@media screen and (max-width: 767px) {
  .reviewBox .mobileSmallPad {
    padding-top: 20px;
  }
}

.couponBox .bp4-input-action {
  height: 100%;
}

.reviewBoxInternal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.reviewBoxInternal .reviewBox {
  border: none;
}
.reviewBoxInternal > span {
  padding: 0 10px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .reviewBoxInternal > span {
    width: 50%;
  }
}
.reviewBoxInternal > span.last {
  margin-bottom: none;
}

.recent-address-select .bp4-button {
  margin: 0;
  position: relative;
  top: -5px;
}
.recent-address-select .bp4-button .bp4-icon {
  margin: 12.5px !important;
}

.recent-address-select-overlay {
  padding: 0;
}
.recent-address-select-overlay > .title {
  background: #fcfcfc;
  padding: 13px 15px 11px;
  font-size: 16px;
  font-weight: "bold";
}
.recent-address-select-overlay .bp4-menu-item {
  border-radius: 0;
  padding: 10px 15px;
}
.recent-address-select-overlay .bp4-menu-item:hover {
  background-color: #c3caf9;
}
.recent-address-select-overlay .bp4-menu-item.bp4-active {
  background-color: #162fda;
  color: #fcfcfc;
}

.selectByRecentDriversSearch.bp4-menu {
  overflow: auto;
  max-height: 300px;
}

.bp4-callout {
  border-radius: 4px;
}
.bp4-callout.bp4-intent-primary {
  color: #183a80;
}
.bp4-callout.bp4-intent-warning {
  color: #935610;
}

.bp4-callout.warningCallout {
  border-radius: 6px;
  padding: 20px;
}
.bp4-callout.warningCallout strong {
  font-weight: 600;
}
.bp4-callout.warningCallout.bp4-callout-icon {
  padding-left: 50px;
  font-size: 15px;
}
.bp4-callout.warningCallout.bp4-callout-icon > .bp4-icon:first-child {
  top: 20px;
  left: 20px;
}
.bp4-callout.warningCallout .calloutLink {
  font-weight: 600;
}

.bp4-callout.bp4-intent-warning.bp4-callout-icon.preferredDriverSelectCallout span.bp4-icon.bp4-icon-warning-sign {
  margin: 0px !important;
}

.bp4-callout.bp4-intent-danger.bp4-callout-icon.preferredDriverSelectCallout span.bp4-icon.bp4-icon-error {
  margin: 0px !important;
}

.bp4-callout.bp4-intent-success.bp4-callout-icon.preferredDriverSelectCallout span.bp4-icon.bp4-icon-tick {
  margin: 0px !important;
}

.preferredDriverSelectCallout--container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.preferredDriverSelectCallout--icon {
  font-size: 24px;
  color: #165a36;
}
.preferredDriverSelectCallout--title {
  font-weight: 500;
  margin-left: 4px;
}
.preferredDriverSelectCallout--content {
  margin-left: 4px;
}

.bp4-menu-item-label {
  display: inline-flex;
  align-items: center;
}

.bp4-popover-dismiss {
  display: inline-flex;
  align-items: center;
}

.bp4-select-popover .bp4-input-group .bp4-input, .bp4-select-popover .bp4-input-group .PhoneInput .PhoneInputInput, .PhoneInput .bp4-select-popover .bp4-input-group .PhoneInputInput {
  padding: 25px 15px;
  border-bottom: 1px solid #d1d1d1;
  border-radius: 3px 3px 0 0;
}
.bp4-select-popover .bp4-input-group .bp4-input:not(:first-child), .bp4-select-popover .bp4-input-group .PhoneInput .PhoneInputInput:not(:first-child), .PhoneInput .bp4-select-popover .bp4-input-group .PhoneInputInput:not(:first-child) {
  padding-left: 40px;
}
.bp4-select-popover .bp4-input-group .bp4-input-action button {
  margin: 0;
  border-radius: 0 3px 0 0;
}
.bp4-select-popover .bp4-input-group .bp4-icon {
  margin: 17px !important;
}

.col-xs.shrink,
.col-sm.shrink,
.col-md.shrink,
.col-lg.shrink {
  flex-grow: inherit;
  flex-shrink: 0;
}

.dropoff-button {
  color: #fff !important;
  background-color: #162fda !important;
}
.dropoff-button.bp4-disabled {
  background-color: #cdced0 !important;
}

@media screen and (min-width: 767px) {
  .desktop-hidden {
    display: none !important;
  }
  .dropoff-button {
    border-width: 1;
    margin: 0;
    display: flex;
    align-self: flex-end;
  }
}
@media screen and (max-width: 767px) {
  .mobile-hidden {
    display: none !important;
  }
  .dropoff-button {
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}
.compact-button {
  transition: all 0.1s ease;
  border: none;
  background: transparent;
  width: auto;
  padding: 0.375rem;
  border-radius: 4px;
  cursor: pointer;
}
.compact-button:hover, .compact-button:focus {
  opacity: 0.6;
}
.compact-button:disabled {
  cursor: not-allowed;
  color: #7f7f7f !important;
}
.compact-button:disabled:hover, .compact-button:disabled:focus {
  opacity: 1;
}

.done-button:not(.bp4-disabled) {
  background: #162fda !important;
  color: #fcfcfc !important;
  border-color: #162fda !important;
}

.PhoneInput .PhoneInputInput {
  outline: none;
  border: none;
  border-radius: 3px;
  background: #ffffff;
  height: 30px;
  line-height: 30px;
  color: #182026;
  font-weight: 400;
  appearance: none;
  width: 100%;
}

.bp4-popover2.popover--color__gray .bp4-popover2-content {
  background-color: #f3f3f3;
}
.bp4-popover2.popover--color__gray .bp4-popover2-arrow-fill {
  fill: #f3f3f3;
}
.bp4-popover2.popover--size__md .bp4-popover2-content {
  width: 500px;
}
@media screen and (max-width: 1024px) {
  .bp4-popover2.popover--size__md .bp4-popover2-content {
    width: 350px;
  }
}

.DuplicateMatch .bp4-button.active {
  background-color: #162fda;
  color: #fcfcfc;
}
.DuplicateMatch__modal.bp4-popover2 .bp4-popover2-content {
  max-width: 600px;
  background-color: #f3f3f3;
}
@media screen and (max-width: 1024px) {
  .DuplicateMatch__modal.bp4-popover2 .bp4-popover2-content {
    max-width: 350px;
  }
}
.DuplicateMatch__modal.bp4-popover2 .bp4-popover2-arrow-fill {
  fill: #f3f3f3;
}
.DuplicateMatch__modal.bp4-popover2 .bp4-input, .DuplicateMatch__modal.bp4-popover2 .PhoneInput .PhoneInputInput, .PhoneInput .DuplicateMatch__modal.bp4-popover2 .PhoneInputInput {
  max-width: 350px;
}
.DuplicateMatch__form .panelDivider {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 16px;
}

.MatchScheduler__container {
  margin-top: 16px;
  margin-bottom: 10px;
  margin-left: 4px;
}
.MatchScheduler .datePicker {
  margin-top: 15px;
  margin-bottom: 15px;
}

.ShipperList__item.disabled td {
  background-color: #f3f3f3;
  color: #7f7f7f;
}

.filters {
  display: flex;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1024px) {
  .filters {
    flex-direction: column;
  }
}
.filters > div {
  display: flex;
  flex: auto;
  flex-grow: 1;
  margin: 0 -0.5rem;
  align-items: center;
}
.filters > div:last-child {
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .filters > div:last-child {
    justify-content: center;
  }
}
@media screen and (max-width: 1024px) {
  .filters > div {
    flex-direction: column;
  }
}
.filters > div > div {
  width: 100%;
  margin: 0 0.5rem;
}
.filters > div > div > .bp4-popover-wrapper {
  width: 100%;
}
.filters > div > div > .bp4-popover-wrapper > .bp4-popover-target {
  width: 100%;
}
@media screen and (min-width: 767px) {
  .filters .search-input {
    float: right;
  }
}
.filters select,
.filters .bp4-input-group .bp4-input,
.filters .bp4-input-group .PhoneInput .PhoneInputInput,
.PhoneInput .filters .bp4-input-group .PhoneInputInput {
  border-radius: 4px;
  height: 43px;
  min-height: none;
  border: 1px solid #7f7f7f;
}
.filters .bp4-input-group,
.filters .select {
  margin: 1rem 0 0;
}
.filters .select,
.filters .search-input {
  max-width: 250px;
}
@media screen and (max-width: 1024px) {
  .filters .select,
  .filters .search-input {
    max-width: 100%;
    width: 100%;
  }
}

.bp4-tab {
  color: #ff9500;
}
.bp4-tab[aria-selected=true], .bp4-tab:not([aria-disabled=true]):hover {
  color: #ff9500;
}

.bp4-tab-indicator-wrapper .bp4-tab-indicator {
  background-color: #ff9500;
}

.form-errors {
  background-color: #f3f3f3;
  border-radius: 5px;
  border: 1px solid #ff8080;
  color: #ff1a1a;
  padding: 20px;
  margin-bottom: 1rem;
}
.form-errors ul {
  padding-left: 1rem;
}
.form-errors ul li {
  margin-bottom: 5px;
}
.form-errors ul li p {
  margin-bottom: 0;
}
.form-errors > ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.input-addon.bp4-button {
  margin: 0;
  padding: 21px 14px;
  height: 43px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.text-button {
  cursor: pointer;
  color: #162fda;
  background: none;
  border: none;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.text-button:hover {
  color: #0f2095;
}

.date-time-card {
  font-weight: 400;
  font-size: 13;
  margin-top: 6;
  margin-bottom: 0;
  padding-bottom: 0;
}

.bp4-timezone-select .bp4-tag {
  margin: 0;
  height: 43px;
}

.shipper-label {
  text-transform: capitalize;
  font-weight: 500;
}
.shipper-label__email {
  text-transform: lowercase;
  color: #7f7f7f;
  font-weight: 300;
}

.driverDataCard__subheader {
  color: #7f7f7f;
}
.driverDataCard__nameHeading {
  font-weight: 600;
  margin-right: 10px;
}
.driverDataCard__cellHeader {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
}
.driverDataCard__profile {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-right: 10px;
}
.driverDataCard__personal {
  display: flex;
}
.driverDataCard__flair {
  align-self: flex-end;
}

.appContent .bp4-input-group .selectByEmailButton {
  padding: 0px;
  margin: 0px;
  border: none;
}

.selectByEmailPopover {
  width: 100%;
}

.appContent .bp4-input-group .selectByNameButton {
  padding: 0px;
  margin: 0px;
  border: none;
}

.selectByNamePopover {
  width: 100%;
}

.message_card {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start !important;
  margin-bottom: 20px;
  padding-left: 16px !important;
  align-items: flex-start !important;
  box-shadow: none;
  gap: 5px;
}
.message_card:hover, .message_card:active {
  box-shadow: none !important;
}

.card_cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card_cell .information {
  display: flex;
  border: 1.5px solid #8a838a;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 0px 5px;
  height: 15px;
  width: 15px;
}
.card_cell .information span {
  color: #8a838a;
  font: 400 12px "Font Awesome 6 Pro";
}

.message_card .notice {
  color: #162fda;
  font: 700 14px "Montserrat";
  margin: 0px;
}
.message_card span {
  color: #000;
  font: 400 14px "Montserrat";
  margin: 0px;
}

.addresses_message span {
  color: #373737;
  font: 700 14px "Montserrat";
  line-height: 18px;
}

.estimate_card {
  background: #fff;
  border: 0;
  width: 540px;
  border-radius: 8px;
  margin-top: 20px;
}
.estimate_card .card_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 20px;
}
.estimate_card .card_header .header_cell {
  display: flex;
  flex-direction: column;
}
.estimate_card .card_header .header_cell .header_first {
  font: 500 0.85em/normal "Montserrat";
  color: #8a838a;
}
.estimate_card .card_header .header_cell .header_second {
  font: 500 1.15em/normal "Montserrat";
  color: #000;
}
.estimate_card .divider {
  background: #f3f3f3;
  width: 540px;
  height: 2px;
}
.estimate_card .card_bottom {
  display: flex;
  flex-direction: row;
  height: 60px;
  flex-shrink: 0;
  border-radius: 0px 0px 8px 8px;
  background: #7f7f7f;
}
.estimate_card .card_bottom .bottom_cell_label {
  font: 700 1.15em/normal "Montserrat";
  color: #fff;
}
.estimate_card .card_bottom .x3 {
  font: 500 1.15em/normal "Montserrat";
  color: #8a838a;
  margin-left: 5px;
}

.vehicleType.bp4-card {
  display: flex;
  flex-direction: column-reverse;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 11px;
  border: white 2px solid;
  padding: 15px 15px 10px;
}
@media screen and (min-width: 767px) {
  .vehicleType.bp4-card:not(.hiddenVehicleClass) {
    border-bottom: 0;
  }
}
.vehicleType.bp4-card.disabledCard {
  cursor: not-allowed;
  opacity: 0.8;
}
.vehicleType.bp4-card.cardSelected {
  border-color: #d9d9d9;
}
@media screen and (min-width: 767px) {
  .vehicleType.bp4-card.cardSelected:not(.hiddenVehicleClass) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -2px;
    padding-bottom: 23px;
  }
}
.vehicleType.bp4-card.cardSelected:hover, .vehicleType.bp4-card.cardSelected:active {
  box-shadow: none;
}
.vehicleType.bp4-card .cardImage {
  min-height: auto;
}
.vehicleType.bp4-card .cardImage img {
  padding: 12px;
  max-width: min(100%, 200px);
  max-height: 100%;
  width: auto;
}
.vehicleType.bp4-card .cardText {
  text-align: center;
}

/* Changing the default shadow of card hover shadows */
.bp4-card.bp4-interactive:hover {
  box-shadow: 1px 1px 14px rgba(16, 22, 26, 0.15);
}

.vehicleClass {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  border: 1px solid #d9d9d9;
  padding: 10px 15px;
  margin: 0 7.5px;
}
@media screen and (max-width: 767px) {
  .vehicleClass:not(:last-child) {
    margin-bottom: 10px;
  }
}
.vehicleClass .radioLabel {
  font-weight: 600;
  margin-bottom: 6px;
}
.vehicleClass.cardSelected {
  border-color: #2a41dd;
  background-color: #eef0ff;
}
.vehicleClass.cardSelected .radioLabel {
  color: #373737;
}
.vehicleClass .cardText {
  color: #8a838a;
}
.vehicleClass .cardText ul {
  padding-left: 6px;
  margin: 0;
}
.vehicleClass .cardText ul li {
  display: flex;
  align-items: center;
}
.vehicleClass .cardText ul li::before {
  content: "•";
  padding-right: 6px;
  font-size: 1rem;
  line-height: 14px;
}

.vehicleSelectWrapper {
  display: flex;
  margin: 0 -10px;
}
@media screen and (max-width: 767px) {
  .vehicleSelectWrapper {
    flex-direction: column;
  }
}

.vehicleClassSelectWrapper {
  display: flex;
  border: #d9d9d9 2px solid;
  background-color: white;
  padding: 15px 7.5px;
  border-radius: 4px;
}
@media screen and (max-width: 767px) {
  .vehicleClassSelectWrapper {
    flex-direction: column;
  }
}

.sectionLabel > .bp4-label:first-of-type,
.sectionLabelDirect {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.sectionLabelExtraPadding {
  margin-top: 15px !important;
}

.slaContainer {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding: 4px 10px;
  border-radius: 5px;
}
.slaContainer--pending {
  background-color: #f3f3f3;
}
.slaContainer--active {
  background-color: #0066ff;
}
.slaContainer--completed {
  background-color: #00c751;
}
.slaContainer .slaETA {
  font-weight: 600;
}
.slaContainer .slaETA--pending {
  color: #373737;
}
.slaContainer .slaETA--active {
  color: #fcfcfc;
}
.slaContainer .slaETA--completed {
  color: #fcfcfc;
}
.slaContainer .slaDetails {
  text-align: end;
  font-size: 14px;
  line-height: 20px;
}
.slaContainer .slaDetails--pending {
  color: #1e1e1e;
}
.slaContainer .slaDetails--active {
  color: #fcfcfc;
}
.slaContainer .slaDetails--completed {
  color: #fcfcfc;
}

.label, .label__warning {
  background-color: #c4c4c4;
  border-radius: 5px;
  color: #fcfcfc;
  font-size: 0.6rem;
  margin-left: 0.8rem;
  padding: 0.166665rem 0.8rem;
  font-weight: bold;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.label .material-icons, .label__warning .material-icons {
  font-size: 0.875rem;
}
.label.large, .large.label__warning {
  font-size: 0.75rem;
  padding: calc(0.8rem / 2) 0.8rem;
}
.label.large .material-icons, .large.label__warning .material-icons {
  font-size: 1.125rem;
}
.label--new {
  background-color: #ff9500;
}
.label--multistop {
  background-color: #0066ff;
}
.label--box-truck {
  background-color: #00c751;
}
.label__state--en_route, .label__state--arrived, .label__state--signed {
  background-color: #ff9500;
}
.label__state--delivered {
  background-color: #00c751;
}
.label__state--undeliverable {
  background-color: #ff4444;
}
.label__warning {
  background-color: #fff;
  color: #cd4246;
  cursor: pointer;
  margin-left: 0rem;
  margin-right: -0.8rem;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  input.bp4-input, .PhoneInput input.PhoneInputInput {
    height: 43px !important;
    padding-top: 7px !important;
  }
  .bp4-input-group.location-search-input input {
    height: 43px !important;
    padding-top: 7px !important;
  }
}
html > body input.bp4-input, html > body .PhoneInput input.PhoneInputInput, .PhoneInput html > body input.PhoneInputInput, x:-moz-any-link input.bp4-input, x:-moz-any-link .PhoneInput input.PhoneInputInput, .PhoneInput x:-moz-any-link input.PhoneInputInput {
  height: 43px !important;
  padding: 0;
  padding-left: 15px;
}
html > body .bp4-input-group.location-search-input input, x:-moz-any-link .bp4-input-group.location-search-input input {
  height: 43px !important;
  padding: 0 !important;
  padding-left: 37px !important;
}

/*# sourceMappingURL=App.css.map */
